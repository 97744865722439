/**
 * @module DisplayField
 */
import React from 'react'
import PropTypes from 'prop-types'
import { TextField, Typography } from '@material-ui/core'

/**
 * The DisplayField component.
 *
 * @alias module:DisplayField
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.disabled] - Optionally disable the field.
 * @param {object} [props.displayProps] - Optional props to pass in to the display component.
 * @param {string} [props.displayValue] - An optional value to display when the field is not active.
 * @param {object} [props.fieldProps] - Optional props to pass in to the field component.
 * @param {string} props.id - The field id.
 * @param {object} [props.inputProps] - Optional props to pass into the base input element.
 * @param {boolean} [props.isFieldActive] - Optionally render the field.
 * @param {Function} [props.onBlur] - An optional onBlur handler.
 * @param {Function} props.onChange - The onChange handler.
 * @param {Function} [props.onFocus] - An optional onFocus handler.
 * @param {string} [props.placeholder] - The field placeholder.
 * @param {string} props.value - The field value.
 *
 * @returns {React.ReactElement} - The DisplayField component.
 */
export function DisplayField({
  disabled,
  displayProps,
  displayValue,
  fieldProps,
  id,
  inputProps,
  isFieldActive,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  value,
}) {
  const [isEditing, setIsEditing] = React.useState(isFieldActive)
  const [initialized, setInitialized] = React.useState(false)
  const fieldRef = React.useRef(null)

  function handleFocus() {
    if (onFocus) {
      onFocus()
    }
    setIsEditing(true)
  }

  function handleBlur() {
    if (onBlur) {
      onBlur()
    }
    setIsEditing(false)
  }

  React.useEffect(() => {
    if (!initialized && isFieldActive && onFocus) {
      onFocus()
      setInitialized(true)
    }
  }, [initialized, isFieldActive, onFocus])

  React.useEffect(() => {
    if (isEditing) {
      fieldRef.current.select()
    }
  }, [isEditing])

  if (isEditing) {
    return (
      <TextField
        autoFocus={true}
        fullWidth={true}
        InputProps={{
          disableUnderline: true,
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...fieldProps}
        disabled={disabled}
        id={id}
        inputProps={inputProps}
        inputRef={fieldRef}
        onChange={onChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        value={value}
      />
    )
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Typography onClick={handleFocus} {...displayProps}>
      {displayValue || value || placeholder}
    </Typography>
  )
}

DisplayField.propTypes = {
  disabled: PropTypes.bool,
  displayProps: PropTypes.shape({}),
  displayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fieldProps: PropTypes.shape({}),
  id: PropTypes.string.isRequired,
  inputProps: PropTypes.shape({}),
  isFieldActive: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
}
DisplayField.defaultProps = {
  disabled: false,
  displayProps: {},
  displayValue: '',
  fieldProps: {},
  inputProps: {},
  isFieldActive: false,
  onFocus: undefined,
  onBlur: undefined,
  placeholder: '',
}
