/**
 * @module ManageListsOptions
 */
import React from 'react'
import PropTypes from 'prop-types'
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  IconButton,
  RootRef,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { arrayMove } from 'utils'
import { DisplayField } from 'components/display-field'

/**
 * The ManageListsOptions component.
 *
 * @alias module:ManageListsOptions
 *
 * @param {object} props - The component props object.
 * @param {Array} props.lists - The lists array.
 * @param {Function} props.onChange - The function to change the lists array.
 * @param {Function} props.onDelete - The function to delete a list.
 *
 * @returns {React.ReactElement} - The ManageListsOptions component.
 */
export function ManageListsOptions({ lists, onChange, onDelete }) {
  function handleChangeListTitle(event, id) {
    onChange((prevState) => {
      const index = prevState.findIndex((item) => item.id === id)
      const newState = [...prevState]
      newState[index].title = event.target.value
      return newState
    })
  }

  /**
   * Sorts the items array after drag and drop is complete.
   *
   * @param {object} result - The drag result.
   */
  function handleDragEnd(result) {
    onChange((array) => {
      return arrayMove({
        array,
        from: result.source.index,
        to: result.destination.index,
      })
    })
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="manage-lists-sortable-drop-area">
        {(providedDroppable) => {
          return (
            <RootRef rootRef={providedDroppable.innerRef}>
              <List
                dense={true}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...providedDroppable.droppableProps}
                ref={providedDroppable.innerRef}
              >
                {lists.map((list, index) => {
                  return (
                    <Draggable
                      draggableId={`manage-lists-item-${list.id}`}
                      index={index}
                      key={`manage-lists-item-${list.id}`}
                    >
                      {(providedDraggable) => {
                        return (
                          <ListItem
                            ContainerProps={{
                              ref: providedDraggable.innerRef,
                            }}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...providedDraggable.draggableProps}
                          >
                            <ListItemIcon
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...providedDraggable.dragHandleProps}
                            >
                              <DragIndicatorIcon />
                            </ListItemIcon>
                            <ListItemText>
                              <DisplayField
                                id={`list-${list.id}-title`}
                                inputProps={{
                                  'aria-label': 'List Title',
                                }}
                                onChange={(event) =>
                                  handleChangeListTitle(event, list.id)
                                }
                                placeholder="Add Title…"
                                value={list.title}
                              />
                            </ListItemText>
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => onDelete(list.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )
                      }}
                    </Draggable>
                  )
                })}
                {providedDroppable.placeholder}
              </List>
            </RootRef>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}

ManageListsOptions.propTypes = {
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}
