/**
 * @module TabBar
 */
import React from 'react'
import PropTypes from 'prop-types'
import {
  AppBar,
  Tabs,
  Tab,
  IconButton,
  Box,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

/**
 * The TabBar component.
 *
 * @alias module:TabBar
 *
 * @param {object} props - The component props object.
 * @param {Array} props.lists - The lists array.
 * @param {Function} props.onAdd - The function to add a new item to the array.
 * @param {Function} props.onChange - The function to change the active list.
 * @param {Function} props.onChangeListTitle - The function to edit the list's title.
 * @param {React.ReactChildren} props.children - The component children.
 *
 * @returns {React.ReactElement} - The TabBar component.
 */
export function TabBar({
  lists,
  onAdd,
  onChange,
  onChangeListTitle,
  children,
}) {
  return (
    <AppBar color="default">
      <Box display="flex" alignItems="center">
        <Box width={50} alignItems="center" justifyContent="center">
          <IconButton onClick={onAdd} aria-label="Add a list" color="primary">
            <AddIcon color="primary" />
          </IconButton>
        </Box>
        <Box width="calc(100% - 50px)">
          <Tabs
            indicatorColor="primary"
            value={lists.find((list) => list.isActive)?.id}
            onChange={onChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Grocery list tabs"
          >
            {lists.map((list) => {
              return (
                <Tab
                  key={list.id}
                  label={
                    list.isActive ? (
                      <TextField
                        aria-label="list title"
                        autoFocus={!list.title}
                        fullWidth={true}
                        inputProps={{
                          style: {
                            textTransform: 'uppercase',
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              {children}
                            </InputAdornment>
                          ),
                        }}
                        onChange={(event) => onChangeListTitle(event, list.id)}
                        placeholder="Add Title…"
                        value={list.title}
                      />
                    ) : (
                      list.title || 'Add Title…'
                    )
                  }
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  id={`list-tab-${list.id}`}
                  aria-controls={`list-${list.id}`}
                  value={list.id}
                />
              )
            })}
          </Tabs>
        </Box>
      </Box>
    </AppBar>
  )
}
TabBar.propTypes = {
  children: PropTypes.node.isRequired,
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.node.isRequired,
    }),
  ).isRequired,
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeListTitle: PropTypes.func.isRequired,
}
