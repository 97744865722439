/**
 * @module ListItem
 */
import React from 'react'
import PropTypes from 'prop-types'
import {
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  IconButton,
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { DisplayField } from 'components/display-field'

const useStyles = makeStyles((theme) => ({
  alignRight: {
    textAlign: 'right',
  },
  listIcon: {
    minWidth: 0,
  },
  hide: {
    display: 'none',
  },
  normalPadding: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  secondaryAction: {
    right: 0,
  },
  disabled: {
    opacity: 0.3,
  },
}))

const fields = {
  quantity: 'quantity',
  description: 'description',
  cost: 'cost',
}

/**
 * @typedef ItemData
 * @property {string} id - The item's id.
 * @property {string} quantity - The item's quantity.
 * @property {string} description - The item's description.
 * @property {string} cost - The item's per-unit pre-tax cost.
 */
/**
 * The list item.
 * Contains checkbox, quantity, description, cost, delete.
 *
 * @alias module:ListItem
 *
 * @param {object} props - The component's props object.
 * @param {ItemData} props.itemData - The item's data object.
 * @param {Function} props.onChange - The function to update the itemData object.
 * @param {Function} props.onDelete - The function to delete the item.
 * @param {Function} props.onEnter - The function to fire when the enter key is pressed.
 * @param {boolean} [props.autofocus] - Optionally determine if this is a new item.
 *
 * @returns {React.ReactElement} - The ListItem component.
 */
export function ListItem({
  itemData,
  onChange,
  onDelete,
  onEnter,
  autofocus,
  ...props
}) {
  const [editingField, setEditingField] = React.useState(
    autofocus ? fields.description : null,
  )

  const classes = useStyles()

  const labelId = `checkbox-list-label-${itemData.id}`

  function resetEditingField() {
    setEditingField(null)
  }

  return (
    <MuiListItem
      dense={true}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={
        editingField !== fields.description ? classes.normalPadding : ''
      }
    >
      <ListItemIcon className={classes.listIcon}>
        <Checkbox
          edge="start"
          checked={itemData.isChecked}
          tabIndex={-1}
          disableRipple={true}
          inputProps={{ 'aria-labelledby': labelId }}
          onChange={() =>
            onChange(itemData.id, { isChecked: !itemData.isChecked })
          }
        />
      </ListItemIcon>
      <ListItemText
        id={labelId}
        primary={
          <Box
            display="flex"
            alignItems="center"
            className={itemData.isChecked ? classes.disabled : ''}
          >
            <Box width={23}>
              <DisplayField
                disabled={itemData.isChecked}
                displayProps={{ align: 'right' }}
                fieldProps={{ maxLength: '2' }}
                id={`item-${itemData.id}-quantity`}
                inputProps={{
                  'aria-label': 'Quantity',
                  className: classes.alignRight,
                  inputMode: 'decimal',
                }}
                onBlur={resetEditingField}
                onChange={(event) =>
                  onChange(itemData.id, { quantity: event.target.value })
                }
                onFocus={() => setEditingField(fields.quantity)}
                placeholder="0"
                value={itemData.quantity}
              />
            </Box>
            <Box ml={1}>
              <Typography>&times;</Typography>
            </Box>
            <Box flexGrow={1} mx={1}>
              <DisplayField
                disabled={itemData.isChecked}
                fieldProps={{
                  onKeyDown: (event) => {
                    if (event.key === 'Enter') {
                      resetEditingField()
                      onEnter(event)
                    }
                  },
                }}
                id={`item-${itemData.id}-description`}
                inputProps={{
                  'aria-label': 'Description',
                }}
                isFieldActive={autofocus}
                onBlur={resetEditingField}
                onChange={(event) =>
                  onChange(itemData.id, { description: event.target.value })
                }
                onFocus={() => setEditingField(fields.description)}
                placeholder="Add description…"
                value={itemData.description}
              />
            </Box>
            {editingField !== fields.description ? (
              <Box width={70}>
                <DisplayField
                  disabled={itemData.isChecked}
                  displayProps={{ align: 'right' }}
                  displayValue={`$${Number(
                    Number(itemData.quantity) * Number(itemData.cost),
                  ).toFixed(2)}`}
                  id={`item-${itemData.id}-cost`}
                  inputProps={{
                    'aria-label': 'Cost',
                    className: classes.alignRight,
                    inputMode: 'decimal',
                  }}
                  onBlur={resetEditingField}
                  onChange={(event) =>
                    onChange(itemData.id, { cost: event.target.value })
                  }
                  onFocus={() => setEditingField(fields.cost)}
                  placeholder="0.00"
                  value={itemData.cost}
                />
              </Box>
            ) : null}
          </Box>
        }
      />
      <ListItemSecondaryAction className={classes.secondaryAction}>
        {editingField === fields.description ? (
          <IconButton onClick={onDelete} onMouseDown={onDelete}>
            <DeleteIcon />
          </IconButton>
        ) : null}
      </ListItemSecondaryAction>
    </MuiListItem>
  )
}

ListItem.propTypes = {
  itemData: PropTypes.shape({
    cost: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
    quantity: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  autofocus: PropTypes.bool,
}
ListItem.defaultProps = {
  autofocus: false,
}
