/**
 * @module AddListItem
 */
import React from 'react'
import PropTypes from 'prop-types'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme) => ({
  listItem: {
    opacity: 0.5,
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
}))

/**
 * The AddListItem component. Clicking on it should add a new list item.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.onClick - The onClick handler function.
 *
 * @returns {React.ReactElement} - The AddListItem component.
 */
export function AddListItem({ onClick }) {
  const classes = useStyles()

  return (
    <ListItem className={classes.listItem} dense={true} onClick={onClick}>
      <ListItemIcon>
        <AddIcon />
      </ListItemIcon>
      <ListItemText>
        <Typography>Add Item</Typography>
      </ListItemText>
    </ListItem>
  )
}
AddListItem.propTypes = {
  onClick: PropTypes.func.isRequired,
}
