/**
 * @module OptionButton
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

/**
 * The OptionButton component.
 *
 * @alias module:OptionButton
 *
 * @param {object} props - The component props object.
 * @param {React.ReactChildren} props.children - The component children.
 * @param {Function} props.onClick - The onClick handler.
 *
 * @returns {React.ReactElement} - The OptionButton component.
 */
export function OptionButton({ children, onClick }) {
  return (
    <Button variant="outlined" onClick={onClick}>
      {children}
    </Button>
  )
}

OptionButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
}
