/**
 * @module OptionsTabPanel
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

/**
 * The options menu tab panel component.
 *
 * @param {object} props - The component props object.
 * @param {number} props.activeTabIndex - The active tab panel's index.
 * @param {React.ReactNode} props.children - The component's React children.
 * @param {number} props.index - The tab panel's index.
 *
 * @returns {React.ReactElement} - The OptionsTabPanel component.
 */
export function OptionsTabPanel({ activeTabIndex, children, index, ...props }) {
  return (
    <Box
      role="tabpanel"
      hidden={activeTabIndex !== index}
      id={`options-tabpanel-${index}`}
      aria-labelledby={`options-tab-${index}`}
      p={2}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {activeTabIndex === index ? children : null}
    </Box>
  )
}

OptionsTabPanel.propTypes = {
  activeTabIndex: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
}
