/**
 * @module TabOptions
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Box, Popover } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

/**
 * The TabOptions component.
 *
 * @alias module:TabOptions
 *
 * @param {object} props - The component props object.
 * @param {React.ReactChildren} props.children - The component children.
 *
 * @returns {React.ReactElement} - The TabOptions component.
 */
export function TabOptions({ children }) {
  const [anchorElement, setAnchorElement] = React.useState(null)
  function handleOpen(event) {
    setAnchorElement(event.currentTarget)
  }
  function handleClose() {
    setAnchorElement(null)
  }

  const isOpen = Boolean(anchorElement)
  const id = isOpen ? 'list-options' : undefined

  return (
    <>
      <MoreVertIcon aria-describedby={id} onClick={handleOpen} />
      <Popover
        anchorEl={anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        id={id}
        open={isOpen}
        onClose={handleClose}
      >
        <Box p={2}>{children}</Box>
      </Popover>
    </>
  )
}

TabOptions.propTypes = {
  children: PropTypes.node.isRequired,
}
