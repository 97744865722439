/**
 * @module FloatingTotal
 */
import React from 'react'
import PropTypes from 'prop-types'
import {
  AppBar,
  Box,
  IconButton,
  Input,
  Typography,
  makeStyles,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const useStyles = makeStyles({
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  taxInput: {
    textAlign: 'right',
    padding: 0,
    paddingRight: 2,
  },
  taxTypography: {
    lineHeight: 1,
  },
})
/**
 * Floating total bar.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.setTax - The function to update tax.
 * @param {number} props.subtotal - The list subtotal.
 * @param {string} props.tax - The tax string.
 * @param {Function} props.toggleOptions - The function to toggle the options dialog menu.
 *
 * @returns {React.ReactElement} - The FloatingTotal component.
 */
export function FloatingTotal({ setTax, subtotal, tax, toggleOptions }) {
  const classes = useStyles()

  function handleSetTax(event) {
    setTax((prevState) => {
      if (Number.isNaN(+event.target.value)) {
        return prevState
      }
      return event.target.value.replace(/ /g, '')
    })
  }

  return (
    <>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Box display="flex" p={3} alignItems="center">
          <Box width={50}>
            <Box display="flex" alignItems="center">
              <Box>
                <Input
                  id="sales-tax"
                  inputProps={{
                    'aria-label': 'Sales tax',
                    className: classes.taxInput,
                    inputMode: 'decimal',
                  }}
                  value={tax}
                  onChange={handleSetTax}
                  placeholder="0.0"
                  color="secondary"
                  disableUnderline={true}
                />
              </Box>
              <Box>
                <Typography className={classes.taxTypography}>%</Typography>
              </Box>
            </Box>
            <Box>
              <Typography align="right" className={classes.taxTypography}>
                tax
              </Typography>
            </Box>
          </Box>
          <Box flexGrow={1} justifyContent="center">
            <Typography variant="h2" align="center">
              ${subtotal}
            </Typography>
          </Box>
          <Box width={50}>
            <IconButton onClick={toggleOptions}>
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Box>
      </AppBar>
    </>
  )
}

FloatingTotal.propTypes = {
  setTax: PropTypes.func.isRequired,
  subtotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  tax: PropTypes.string.isRequired,
  toggleOptions: PropTypes.func.isRequired,
}
