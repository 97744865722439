/* eslint-disable jsdoc/require-file-overview */
import React from 'react'
import ReactDOM from 'react-dom'
import { CssBaseline, ThemeProvider, createMuiTheme } from '@material-ui/core'
import { deepPurple, teal } from '@material-ui/core/colors'
import App from './views/app'
// import reportWebVitals from './reportWebVitals'

const appTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      light: deepPurple[400],
      main: deepPurple[500],
      dark: deepPurple[700],
    },
    secondary: {
      light: teal[400],
      main: teal[500],
      dark: teal[700],
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
