/**
 * @module ActiveListOptions
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

/**
 * @typedef ActiveListOption
 * @property {string} key - A unique key.
 * @property {Function} onClick - The button click handler.
 * @property {string} text - The button text.
 */
/**
 * Options component for the currently active list.
 *
 * @alias module:ActiveListOptions
 *
 * @param {object} props - The component props object.
 * @param {React.ReactChildren} props.children - An array of list options objects.
 *
 * @returns {React.ReactElement} - The ActiveListOptions component.
 */
export function ActiveListOptions({ children }) {
  return (
    <>
      {React.Children.map(children, (child, index) => {
        return (
          <Box key={`active-list-option-${index}`} mb={2}>
            {child}
          </Box>
        )
      })}
    </>
  )
}

ActiveListOptions.propTypes = {
  children: PropTypes.node.isRequired,
}
