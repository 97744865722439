/**
 * @module List
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Box, List as MuiList, RootRef } from '@material-ui/core'
import { v4 as newId } from 'uuid'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { arrayMove } from 'utils'
import { ListItem } from 'components/list-item'
import { AddListItem } from 'components/add-list-item'

/**
 * The list component.
 *
 * @alias module:List
 *
 * @param {object} props - The component props object.
 * @param {Array} props.listItems - The list items array.
 * @param {string} props.droppableId - The droppableId prop.
 * @param {Function} props.onChange - The function to update items.
 *
 * @returns {React.ReactElement} - The List component.
 */
export function List({ listItems, droppableId, onChange, ...props }) {
  const [latestItemId, setLatestItemId] = React.useState(null)

  /**
   * Adds a new item to the end of the items array.
   *
   * @param {number} index - The index at which to insert the new item.
   */
  function handleAddItem(index) {
    const newItemId = newId()
    onChange((prevState) => {
      const newState = [...prevState]
      newState.splice(index, 0, {
        id: newItemId,
        quantity: '1',
        description: '',
        cost: '0.00',
        isChecked: false,
      })
      return newState
    })
    setLatestItemId(newItemId)
  }

  /**
   * Changes an item in the list array.
   *
   * @param {string} id - The item's id.
   * @param {object} params - One or more params to update.
   */
  function handleChangeItem(id, params) {
    onChange((prevState) => {
      const itemIndex = prevState.findIndex((item) => item.id === id)
      const newState = [...prevState]
      newState[itemIndex] = {
        ...prevState[itemIndex],
        ...params,
        id,
      }
      return newState
    })
  }

  /**
   * Deletes an item from the list array.
   *
   * @param {string} id - The item's id.
   */
  function handleDeleteItem(id) {
    onChange((prevState) => {
      const itemIndex = prevState.findIndex((item) => item.id === id)
      const newState = [...prevState]
      newState.splice(itemIndex, 1)
      return newState
    })
  }

  /**
   * Sorts the items array after drag and drop is complete.
   *
   * @param {object} result - The drag result.
   */
  function handleDragEnd(result) {
    onChange((array) => {
      return arrayMove({
        array,
        from: result.source.index,
        to: result.destination.index,
      })
    })
  }

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Box mb={16} mt={8}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={droppableId}>
            {(providedDroppable) => {
              return (
                <RootRef rootRef={providedDroppable.innerRef}>
                  <MuiList
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...providedDroppable.droppableProps}
                    ref={providedDroppable.innerRef}
                  >
                    {listItems.map((itemData, index) => {
                      return (
                        <Draggable
                          draggableId={itemData.id}
                          index={index}
                          key={itemData.id}
                        >
                          {(providedDraggable) => {
                            return (
                              <ListItem
                                ContainerProps={{
                                  ref: providedDraggable.innerRef,
                                }}
                                itemData={itemData}
                                onChange={handleChangeItem}
                                onDelete={() => handleDeleteItem(itemData.id)}
                                onEnter={() => handleAddItem(index + 1)}
                                autofocus={Boolean(
                                  itemData.id === latestItemId,
                                )}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...providedDraggable.draggableProps}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...providedDraggable.dragHandleProps}
                              />
                            )
                          }}
                        </Draggable>
                      )
                    })}
                    {providedDroppable.placeholder}
                    <AddListItem
                      onClick={() => handleAddItem(listItems.length)}
                    />
                  </MuiList>
                </RootRef>
              )
            }}
          </Droppable>
        </DragDropContext>
      </Box>
    </div>
  )
}

List.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      cost: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string.isRequired,
      isChecked: PropTypes.bool,
      quantity: PropTypes.string,
    }),
  ).isRequired,
  droppableId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
