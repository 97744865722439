/**
 * @module OptionsDialog
 */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  IconButton,
  Slide,
  makeStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { OptionsTabPanel } from 'views/options-dialog/components'

const useStyles = makeStyles({
  dialog: {
    height: '66vh',
  },
  toolbar: {
    paddingRight: 0,
  },
})

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...props} />
})

/**
 *
 * The app options dialog modal.
 *
 * @param {object} props - The component props object.
 * @param {React.ReactChildren} props.children - The options dialog children.
 * @param {boolean} props.isOpen - The open state of the dialog modal.
 * @param {Function} props.onClose - The function to close the dialog modal.
 *
 * @returns {React.ReactElement} - The options dialog component.
 */
export function OptionsDialog({ children, isOpen, onClose }) {
  const classes = useStyles()

  const [activeTabIndex, setActiveTabIndex] = React.useState(0)

  function handleChangeTab(event, newValue) {
    setActiveTabIndex(newValue)
  }

  return (
    <Dialog
      fullWidth={true}
      onClose={onClose}
      open={isOpen}
      aria-labelledby="options-title"
      TransitionComponent={Transition}
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <AppBar position="static">
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Tabs
            value={activeTabIndex}
            onChange={handleChangeTab}
            aria-label="Options tabs"
          >
            {React.Children.map(children, (child, index) => {
              return (
                <Tab
                  key={`options-tab-${child.displayName}`}
                  label={child.props.title}
                  id={`options-tab-${index}`}
                  aria-controls={`options-tabpanel-${index}`}
                />
              )
            })}
          </Tabs>
        </Toolbar>
      </AppBar>
      {React.Children.map(children, (child, index) => {
        return (
          <OptionsTabPanel
            key={`options-tab-${index}`}
            activeTabIndex={activeTabIndex}
            index={index}
          >
            {child}
          </OptionsTabPanel>
        )
      })}
    </Dialog>
  )
}
OptionsDialog.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
