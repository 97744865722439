/**
 * @module App
 */
import React from 'react'
import { Container, makeStyles } from '@material-ui/core'
import { v4 as newId } from 'uuid'
import { List } from 'components/list'
import {
  ActiveListOptions,
  ManageListsOptions,
  OptionButton,
} from 'components/options-menus'
import { TabBar } from 'components/tab-bar'
import { TabOptions } from 'components/tab-options'
import { FloatingTotal } from 'views/floating-total'
import { OptionsDialog } from 'views/options-dialog'

const useStyles = makeStyles({
  globalContainer: {
    maxWidth: 800,
  },
})

/**
 * The primary app component.
 *
 * @alias module:App
 *
 * @returns {React.ReactElement} - The primary app component.
 */
export default function App() {
  const classes = useStyles()
  const [lists, setLists] = React.useState(
    () => JSON.parse(localStorage.getItem('grocery-lists')) || [],
  )

  const activeList = lists.find((list) => list.isActive)

  let localStorageKey
  if (activeList) {
    localStorageKey = `grocery-list_${activeList.id}`
  }
  const [activeListItems, setActiveListItems] = React.useState(() => {
    if (localStorageKey) {
      const localItems = JSON.parse(localStorage.getItem(localStorageKey))
      return Array.isArray(localItems) ? localItems : []
    }
    return []
  })
  const [activeListTax, setActiveListTax] = React.useState(() => {
    if (localStorageKey) {
      const localTax = localStorage.getItem(`${localStorageKey}_tax`)
      return localTax || '0'
    }
    return '0'
  })
  const [isOptionsDialogOpen, setIsOptionsDialogOpen] = React.useState(false)

  const subtotal = Number(
    activeListItems
      .map((item) => Number(item.quantity) * Number(item.cost))
      .reduce((a, b) => a + b, 0) *
      (1 + Number(activeListTax) / 100),
  ).toFixed(2)

  function deactivatedLists(listsArray) {
    return listsArray.map((item) => {
      return { ...item, isActive: false }
    })
  }
  function handleChangeActiveList(event, id) {
    setLists((prevState) => {
      const index = prevState.findIndex((item) => item.id === id)
      if (index < 0) {
        return prevState
      }
      const updatedArray = deactivatedLists(prevState)
      updatedArray[index].isActive = true
      return [...updatedArray]
    })
  }

  function handleAddList() {
    const id = newId()
    setLists((prevState) => {
      return [{ id, title: '', isActive: true }, ...deactivatedLists(prevState)]
    })
  }

  function handleChangeListTitle(event, id) {
    setLists((prevState) => {
      const index = prevState.findIndex((item) => item.id === id)
      const newState = [...prevState]
      newState[index].title = event.target.value
      return newState
    })
  }

  function handleDeleteList(listId) {
    localStorage.removeItem(`grocery-list_${listId}`)
    localStorage.removeItem(`grocery-list_${listId}_tax`)
    setLists((prevState) => {
      if (prevState.length === 1) {
        return []
      }
      const index = prevState.findIndex((item) => item.id === listId)
      const newState = [...prevState]
      // We need to set another element to active. Based on UI button position, try to activate the list to the right first, then left if this list is not the last one.
      if (newState[index + 1]) {
        newState[index + 1].isActive = true
      } else if (newState.length > 1) {
        newState[index - 1].isActive = true
      }

      newState.splice(index, 1)
      return newState
    })
  }

  /**
   * Remove all checklist items.
   */
  function handleClearList() {
    setActiveListItems([])
  }

  /**
   * Uncheck all items.
   */
  function handleResetList() {
    setActiveListItems((prevState) => {
      return prevState.map((item) => {
        return { ...item, isChecked: false }
      })
    })
  }

  React.useEffect(() => {
    localStorage.setItem('grocery-lists', JSON.stringify(lists))
  }, [lists])

  React.useEffect(() => {
    const localItems = JSON.parse(localStorage.getItem(localStorageKey))
    const localTax = localStorage.getItem(`${localStorageKey}_tax`)
    setActiveListItems(Array.isArray(localItems) ? localItems : [])
    setActiveListTax(localTax || '0')
  }, [localStorageKey])

  React.useEffect(() => {
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, JSON.stringify(activeListItems))
    }
  }, [activeListItems, localStorageKey])

  React.useEffect(() => {
    if (localStorageKey) {
      localStorage.setItem(`${localStorageKey}_tax`, activeListTax)
    }
  }, [activeListTax, localStorageKey])

  return (
    <Container className={classes.globalContainer}>
      <TabBar
        onAdd={handleAddList}
        onChange={handleChangeActiveList}
        lists={lists}
        onChangeListTitle={handleChangeListTitle}
      >
        <TabOptions>
          <ActiveListOptions title="List Options">
            <OptionButton onClick={handleClearList}>
              Remove all items
            </OptionButton>
            <OptionButton onClick={handleResetList}>
              Un-check all items
            </OptionButton>
            <OptionButton onClick={() => handleDeleteList(activeList.id)}>
              Delete this list
            </OptionButton>
          </ActiveListOptions>
        </TabOptions>
      </TabBar>
      {activeList ? (
        <List
          id={`list-${activeList.id}`}
          aria-labelledby={`list-tab-${activeList.id}`}
          listItems={activeListItems}
          droppableId={localStorageKey}
          onChange={setActiveListItems}
        />
      ) : null}

      {lists.length ? (
        <>
          <FloatingTotal
            toggleOptions={() =>
              setIsOptionsDialogOpen((prevState) => !prevState)
            }
            subtotal={subtotal}
            tax={activeListTax}
            setTax={setActiveListTax}
          />
          <OptionsDialog
            isOpen={isOptionsDialogOpen}
            onClose={() => setIsOptionsDialogOpen(false)}
          >
            <ManageListsOptions
              title="Manage Lists"
              lists={lists}
              onChange={setLists}
              onDelete={handleDeleteList}
            />
          </OptionsDialog>
        </>
      ) : null}
    </Container>
  )
}
